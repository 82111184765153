export function trackerCategoryRepr(category) {
  const c = category.split('_').join(' ');
  return c.charAt(0).toUpperCase() + c.slice(1);
}


export function search({
    query,
    idx,
    max_results = 10,
  }) {
    const matches = [];
    for (const elt of idx) {
      // is identical
      if (query === elt.label) {
        elt.score = 100;
        matches.push(elt);
      } else if (elt.label.indexOf(query) !== -1) {
        elt.score = 50;
        matches.push(elt);
      } else {
        // TODO: tokenize elt
        // tokens = query.split(" ");
        if (matches.length < max_results) {
          if (editDistance(query, elt.label) < 3) {
            elt.score = 30;
            matches.push(elt);
          } else if (editDistance(query, elt.label) < 4) {
            elt.score = 40;
            matches.push(elt);
          }
        }
      }
    }
    return matches
      .sort(compare)
      .reverse()
      .slice(0, max_results);
    // return matches.slice(0, max_results);
  }

  function compare(a, b) {
    if (a.score < b.score) {
      return -1;
    }
    if (a.score > b.score) {
      return 1;
    }
    return 0;
  }

  function editDistance(s1, s2) {
    const m = s1.length;
    const n = s2.length;
    const dTable = [];

    for (let i = 0; i < m + 1; i += 1) {
      dTable.push(new Array(n + i).fill(0));
    }

    for (let i = 0; i < m + 1; i += 1) {
      for (let j = 0; j < n + 1; j += 1) {
        if (i === 0) {
          dTable[i][j] = j;
        } else if (j === 0) {
          dTable[i][j] = i;
        } else if (s1[i - 1] === s2[j - 1]) {
          dTable[i][j] = dTable[i - 1][j - 1];
        } else {
          dTable[i][j] =
            1 +
            Math.min(dTable[i][j - 1], dTable[i - 1][j], dTable[i - 1][j - 1]);
        }
      }
    }
    return dTable[m][n];
  }


export function getFormattedDate() {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const d = new Date();

  return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`
}
