import React from 'react';
import { Grid, Typography, Divider, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// import { Link } from 'gatsby';
import SignUp from '../components/signup';
import NotificationsIcon from '@material-ui/icons/NotificationsTwoTone';

import withRoot from '../withRoot';


const styles = theme => ({
  root: {
    height: 200,
    backgroundColor: theme.palette.background.gray,
  },
  content: {
    maxWidth: '100%',
    margin: 'auto',
    paddingTop: 24,
  },
  container: {
    margin: 'auto',
    maxWidth: 1000,
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
    width: 50,
    height: 6,
    borderRadius: 8,
    marginBottom: 20
  },
  subscribeIcon: {
    // color: 'inherit',
    color: theme.palette.secondary.main,
    marginRight: 15,
  },
  CTAbutton: {
    padding: 10,
    color: theme.palette.secondary.main,
    border: `solid 2px ${theme.palette.secondary.main}`,
    background: theme.palette.background.paper
  }
});


function Footer(props) {
  const classes = props.classes;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container spacing={5} className={classes.content}>
          <Grid item sm={6} xs={12}>
            <Typography style={{fontWeight: 'bold'}}>© LastParty</Typography>
            <Divider className={classes.divider} />
            {/* <Typography>Privacy Policy</Typography> */}
          </Grid>
          <Grid item sm={6} xs={12}>
            <SignUp
              CTAcomponent={
                <Button className={classes.CTAbutton}>
                  <NotificationsIcon className={classes.subscribeIcon} />
                  Sign Up for Early Beta
                </Button>
              }
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default withRoot(withStyles(styles)(Footer));
