import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CheckIcon from '@material-ui/icons/Check';
import SignUpIcon from '@material-ui/icons/PermIdentityTwoTone';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import addToMailchimp from 'gatsby-plugin-mailchimp';
import withRoot from '../withRoot';
import { Typography, Avatar } from '@material-ui/core';
import DivHeader from '../layout/DivHeader';


const styles = theme => ({
  title: {
    fontFamily: '"Work Sans", sans-serif',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    marginBottom: -15,
  },
  description: {
    marginTop: 0
  },
  avatar: {
    textAlign: 'center',
    background: "#007aff",
    width: 72,
    height: 72,
    marginTop: 25,
    marginBottom: 25,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  successText: {
    fontFamily: '"Work Sans", sans-serif',
  },
  subscribeIcon: {
    // color: 'inherit',
    color: theme.palette.secondary.main,
    marginRight: 15,
  },
})


function Transition(props) {
  return <Slide direction="up" {...props} />;
}


class SignUp extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const classes = this.props.classes;
    return (
      <div>
        <div color="inherit" onClick={this.handleClickOpen}>
          {this.props.CTAcomponent}
        </div>
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <Form classes={classes} />
        </Dialog>
      </div>
    );
  }
}


SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(SignUp));



class Form extends React.Component {
  state = {
    name: null,
    email: null,
    done: false,
  }

  _handleChange = (e) => {
    console.log({
      [`${e.target.name}`]: e.target.value,
    });
    this.setState({
      [`${e.target.name}`]: e.target.value,
    });
  }

  _handleSubmit = (e) => {
    e.preventDefault();

    console.log('submit', this.state);

    addToMailchimp(this.state.email, this.state)
      .then(({ msg, result }) => {
        console.log('msg', `${result}: ${msg}`);
        if (result !== 'success') {
          throw msg;
        } else if (result === 'success') {
          this.setState({ done: true });
        }
      })
      .catch((err) => {
        console.log('err', err);
        alert(err);
      });
  }

  render() {
    const classes = this.props.classes;

    const form =
      <div>
        <div style={{ padding: 15, marginBottom: -25 }}>
          <DivHeader
            icon={<SignUpIcon />}
            title={'Sign Up'}
            description={'Limited number of users accepted for Early Beta'}
          />
        </div>
        <DialogContent>
          <form onSubmit={this._handleSubmit}>
            <TextField
              autoFocus={true}
              onChange={this._handleChange}
              margin="dense"
              id="name"
              name="name"
              variant='outlined'
              label="Name"
              type="text"
              fullWidth
            />
            <TextField
              variant='outlined'
              onChange={this._handleChange}
              margin="dense"
              id="email"
              name="email"
              label="Email Address"
              type="email"
              fullWidth
            />
            <br />
            <br />
            <div style={{ textAlign: 'right' }}>
              <Button
                color="inherit"
                style={{ backgroundColor: '#eaf3ff', color: 'blue', border: 'solid 2px blue', marginTop: 15, marginBottom: 20, padding: '8px 42px' }}
                type="submit"
              >
                Sign Up
            </Button>

            </div>
          </form>
        </DialogContent>
      </div>

    const success = <div>
      <DialogTitle id="alert-dialog-slide-title">
        <Typography variant='button' className={classes.successText} color='textSecondary'>You have been subscribed successfully</Typography>
      </DialogTitle>
      <DialogContent>
        <Avatar className={classes.avatar}>
          <CheckIcon color="white" fontSize='large' />
        </Avatar>
      </DialogContent>
    </div>

    const body = this.state.done ? success : form;

    return (body);
  }
}
