import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';

import withRoot from '../withRoot';


const styles= theme => ({
  // TYPOGRAPHY related
  bold: {
    fontWeight: 'bold',
  },
  sectionDark: {
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    marginBottom: 30,
    borderRadius: theme.shape.borderRadius,
  },
  darkBG: {
    paddingTop: 30,
    paddingBottom: 30,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
  },
  container: {
    padding: theme.spacing(1) * 3,
    margin: 'auto',
    maxWidth: 1000,
  },
  progress: {
    margin: theme.spacing(1) * 2,
  },
  iconbuttonprogress: {
    background: 'white',
  },
    featureSnippet: {
    'borderRadius': theme.shape.borderRadius,
    '&:hover': {
      background: '#EDEEF3',
    },
  },

})


function GridSubFeature(props) {
  const classes = props.classes;
  return (
    <Grid item sm={6} xs={12} className={classes.featureSnippet}>
      <Typography
        variant='button'
        className={classes.bold}
      >
        {props.title}
      </Typography>
      <Typography
        className={props.classes.description}
        style={{ color: 'gray' }}
      >
        {props.desc}
      </Typography>
    </Grid>
  );
}


function Features(props) {
  const classes = props.classes;
  return (
    <div className={classes.darkBG}>
      <div className={classes.container}>
        <div className={classes.sectionDark}>

          <Grid container spacing={4}>
            <GridSubFeature
              classes={classes}
              title={'Header Analysis'}
              desc={
                'High Frequency monitoring of security headers and content security policies on your site - minimizing the threat vector you are exposed to.'
              }
            />
            <GridSubFeature
              classes={classes}
              title={'Processor Mapping'}
              desc={
                'Identification, frequency and location of appearance in your site, tracking capabilities and tech.'
              }
            />
            <GridSubFeature
              classes={classes}
              title={'Loading Chain Analysis'}
              desc={
                'Transparency on how third parties are loaded in your site, making it easy for you to know who the sub-processors of data are.'
              }
            />
            <GridSubFeature
              classes={classes}
              title={'Subresource Integrity'}
              desc={
                'Real-time anomaly detection, monitoring and analysis of the scripts third parties load on your page.'
              }
            />
            <GridSubFeature
              classes={classes}
              title={'Cookie Analysis'}
              desc={
                'Monitoring and alerting to help you not worry about the compliance your site and the processors in it.'
              }
            />
            <GridSubFeature
              classes={classes}
              title={'Automated data leakage modelling'}
              desc={
                'For each sensitive data point on the page, we model their accessibility to third parties, enabling you to label and automatically get privacy and security recommendations.'
              }
            />
            <GridSubFeature
              classes={classes}
              title={'Blockage Rate Prediction'}
              desc={
                'Reliable predictions the  expected percentage of visitors who will block your processers from loading functionality, enabling you to negotiate terms, and choose wisely.'
              }
            />
            <GridSubFeature
              classes={classes}
              title={'Reporting'}
              desc={
                'Exporting Data and Visualizations to distribute within your organizations easily.'
              }
            />
          </Grid>

        </div>
      </div>
    </div>
  )
}

export default withRoot(withStyles(styles)(Features));
