import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import withRoot from '../withRoot';

import { Avatar, Typography, Divider } from '@material-ui/core';


const styles = theme => ({
  header: {
    display: 'flex',
    marginBottom: 25,
  },
  heading: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(21),
    textTransform: 'uppercase',
    marginBottom: 0,
    // fontWeight: theme.typography.fontWeightRegular,
  },
  headerIconButton: {
    border: `solid 2px ${theme.palette.secondary.main}`,
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: '#EDEEF3',
    color: theme.palette.secondary.main,
    marginRight: 20,
    marginTop: 13,
    width: 35,
    height: 35,
  },
  divider: {
    backgroundColor: '#d4d7e6',
    width: 42,
    height: 6,
    borderRadius: 8,
    marginBottom: 15
  }
})


function Header(props) {
  return (
    <div className={props.classes.header}>
      <Avatar component='span' className={props.classes.headerIconButton}>
        {props.icon}
      </Avatar>
      <div>
      <Typography
        variant="h6"
        className={props.classes.heading}
        style={{ color: props.color || '' }}
      >
        {props.title}
      </Typography>
      <Typography
        variant='body1'
        gutterBottom
        style={{ color: '#555' }}
      >
        {props.description}
      </Typography>
      <Divider className={props.classes.divider} />
      </div>
    </div>
  );
}

export default withRoot(withStyles(styles)(Header));
