import {
  AppBar,
  // Button,
  Chip,
  CssBaseline,
  // Menu,
  // MenuItem,
  Divider,
  Toolbar,
  Typography,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircleTwoTone';
import React from 'react';
import { Link } from 'gatsby';
import withRoot from '../withRoot';

import SignUp from '../components/signup';
// import NotificationsIcon from '@material-ui/icons/NotificationsTwoTone';


const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
  },
  grow: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1) * 3,
  },
  toolbar: theme.mixins.toolbar,
  logo: {
    height: 50,
  },
  navoptions: {
    margin: 'auto',
    maxWidth: 1000,
    width: 'inherit',
  },
  accountButton: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.secondary.main,
    border: `solid 2px ${theme.palette.secondary.main}`,
    '&:hover': {
      // backgroundColor: theme.palette.grey.secondaryBg,
      color: theme.palette.primary.white,
    },
    '&:focus': {
      backgroundColor: theme.palette.grey.secondaryBg,
      color: theme.palette.primary.white,
    },
  },
  accountIconButton: {
    marginLeft: 5,
    marginRight: -3,
    color: 'inherit',
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
    width: 50,
    height: 6,
    borderRadius: 8,
    marginBottom: 20
  },

});


class MenuAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: true,
      anchorEl: null,
    };
  }

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  }

  render() {
    const classes = this.props.classes;

    // const open = Boolean(this.state.anchorEl);
    const HomepageLink = props => <Link to="/" {...props} />

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position='fixed' className={classes.appBar}>
          <Toolbar className={classes.navoptions}>
            <Typography
              component={HomepageLink}
              style={{color: 'black', fontWeight: 'bold', marginBottom: -20, textDecoration: 'none'}}
              className={classes.grow}
              noWrap
            >
              LastParty
              <Divider className={classes.divider} />
              {/* <Button className={classes.logo} component={HomepageLink}>
                <img
                  src={`/logo.svg`}
                  alt={'LastParty'}
                  style={{ height: 40, marginTop: -5 }}
                />
              </Button> */}
            </Typography>

            <div>
              <SignUp
                CTAcomponent={
                  <Chip
                  className={classes.accountButton}
                  clickable={true}
                  onClick={this.handleMenu}
                  label={<Typography style={{textTransform: 'uppercase', fontSize: 15, marginRight: 7, fontWeight: 'bold'}}>Account</Typography>}
                  icon={
                    <AccountCircleIcon className={classes.accountIconButton} />
                  }
                  />
                }
              />
              {/* <Menu
                id='menu-appbar'
                anchorEl={this.state.anchorEl}
                open={open}
                onClose={this.handleClose}
              >
                <MenuItem onClick={this.handleClose}>Profile</MenuItem>
                <MenuItem onClick={this.handleClose}>My account</MenuItem>
              </Menu> */}
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withRoot(withStyles(styles)(MenuAppBar));
