import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

import { Link } from "gatsby";
import { navigate } from "gatsby"

import { search } from '../utils';


const default_suggestions = [
  { label: 'economist.com', value: 'economist.com' },
  { label: 'lefigaro.fr', value: 'lefigaro.fr' },
  { label: 'spiegel.de', value: 'spiegel.de' },
  { label: 'politico.com', value: 'politico.com' },
  { label: 'focus.de', value: 'focus.de' },
  { label: 'theguardian.com', value: 'theguardian.com' },
]

const styles = theme => ({
  root: {
    flexGrow: 1,
    // height: 250,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    maxWidth: 700,
    margin: 'auto'
  },
  textfield: {
    padding: '10px 5px',
  },
  input: {
    display: 'flex',
    // padding: 0,
    padding: '6px 15px',
    borderRadius: theme.shape.borderRadius,
    // boxShadow: theme.shadows[1],
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1) * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    // left: 0,
    paddingLeft: 4,
    fontSize: 16,
  },
  paper: {
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      className={props.selectProps.classes.textfield}
      InputProps={{
        disableUnderline: true,
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <Link style={{textDecoration: 'none'}} to={`/scan/${props.children}`}>
      <MenuItem
        buttonRef={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
          backgroundColor: props.isFocused ? '#EDEEF3': 'white',
          fontWeight: props.isSelected ? 500 : 400,
          color: props.isFocused ? 'black': '#333'
        }}
        {...props.innerProps}
      >
        {props.children}
      </MenuItem>
    </Link>

  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}


function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class IntegrationReactSelect extends React.Component {
  state = {
    value: null,
    options: this.props.idx,
    visibleOptions: default_suggestions,
  };

  handleChange = name => value => {
    try {
      this.setState({ value: value });
      if (value) {
        navigate(`/scan/${value.value}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  onInputChange = (query) => {
    if (!query) {
      this.setState({ visibleOptions: default_suggestions });
    } else {
      const matches = search({ query: query, idx: this.props.idx, max_results: 6 });
      this.setState({ visibleOptions: matches });
    }
  }

  noOptionsMessage() {
    return "No sites in public cache match your query";
  }


  render() {
    const { classes, theme } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };


    return (
      <div className={classes.root}>
        <NoSsr>
          <Select
            classes={classes}
            styles={selectStyles}
            options={this.state.visibleOptions}
            components={components}
            onInputChange={this.onInputChange}
            value={this.state.value}
            onChange={this.handleChange('value')}
            noOptionsMessage={this.noOptionsMessage}
            placeholder="Search publicly cached scans"
            isClearable
          />
        </NoSsr>
      </div>
    );
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);
